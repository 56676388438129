<template>
  <div class="content">
    <div class="settings">
      <div
        class="settings__dropzone"
        data-signal-drop
        v-on:drop="onDropSignalSettings($event)"
        v-on:dragenter.prevent="dragEnterStyle($event)"
        v-on:dragover.prevent="dragEnterStyle($event)"
        v-on:dragleave.prevent="dragLeaveStyle($event)"
      >
      <span v-if="signals.length < 1">
        Signale ins Feld ziehen
      </span>
      <span
          class="settings__signal"
          v-for="(signal, index) in signals"
          v-bind:key="index"
          v-bind:class="{'not' : signal.startsWith('not.')}"
      >
          <button
              v-on:click="toggleSignal(signal)"
          >
            {{signal}}
          </button>
          <button v-on:click="removeSignal(signal)">
            <Icon name="cancel" css-class="settings__signal-icon" />
          </button>
      </span>
      </div>
      <div class="settings__inputs">
        <label class="settings__input-block">
          <span class="label">Priorität (0 - 100)</span>
          <input class="input" v-model="priority" type="number" min="0" max="100" @change="saveSettings"/>
        </label>
        <label class="settings__input-block">
          <span class="label">Gewichtung (0 - 100)</span>
          <input class="input" v-model="weight" type="number" min="0" max="100"  @change="saveSettings"/>
        </label>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from "@/components/generic/Icon";
import DragDrop from "@/classes/DragDrop";

export default {
  name: 'Settings',
  mixins: [DragDrop],
  components: {
    Icon,
  },
  props: {
    dataHandler: {
      type: Object,
    },
    routerData: {
      type: Object,
    },
  },
  data() {
    return {
      signals: this.routerData.selectors,
      priority: this.routerData.priority,
      weight: this.routerData.weight,
    };
  },
  methods: {
    toggleSignal(signal) {
      const pos = this.signals.indexOf(signal);
      if (signal.startsWith('not.')) {
        this.signals[pos] = signal.replace('not.', '');
      } else {
        this.signals[pos] = 'not.' + signal;
      }
    },
    removeSignal(signal) {
      const pos = this.signals.indexOf(signal);
      this.signals.splice(pos, 1);
    },
    saveSettings() {
      this.dataHandler.saveSettings(this.priority, this.weight);
    },
  },
}
</script>

<style lang="scss" scoped>
.settings {

  &__dropzone {
    display: flex;
    align-items: center;
    background-color: #FFFFFF;
    padding: rem(10px);
    min-height: rem(50px);
    border: 2px solid var(--border-light-2);
    margin-bottom: rem(40px);
    border-radius: rem(5px);

    &.dragover  {
      border: 1px dashed var(--border-light-blue);
    }
  }

  &__signal {
    display: inline-flex;
    align-items: center;
    position: relative;
    font-size: rem(12px);
    background-color: var(--bg-light-blue);
    border-radius: rem(15px);
    padding: rem(6px) rem(10px);
    margin: rem(2px);
    user-select: none;

    &.not {
      background-color: var(--bg-light-red);
    }

    button {
      font-size: rem(12px);
      line-height: 0;

      &:last-of-type {
        margin-left: rem(6px);
      }
    }
  }

  &__signal-icon {
    height: rem(10px);
  }

  &__inputs {
    display: flex;
    margin-bottom: rem(30px);
  }

  &__input-block {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    &:first-of-type {
      margin-right: rem(20px);
    }
  }
}

</style>